<template>
  <div>
    <div class="c-admin-panel">
      <form :data-vv-scope="updateFormScope" @submit.prevent="submitForm">
        <div class="c-admin-panel-section">
          <div class="row">
            <div class="col-12">
              <h3 class="c-admin-panel-title">
                User Details
              </h3>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="name"
                  label="Name"
                  error-name="name"
                  validation="required"
                />
              </div>

              <div>
                <v-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="email_address"
                  label="Email address"
                  error-name="email address"
                  validation="required|email"
                />
              </div>

              <div>
                <v-phone-number-text-input
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  field="phone_number"
                  type="tel"
                  label="Mobile Number"
                  error-name="mobile number"
                  autocomplete="tel"
                  :maxlength="13"
                  validation="required|numeric"
                />
              </div>

              <div>
                <v-select-input
                  class="mb-4"
                  :scope="updateFormScope"
                  :state-key="stateKey"
                  :options="$store.state.roles"
                  :center-error="false"
                  field="role"
                  placeholder="Select a role"
                  error-name="role"
                  validation="required"
                  label="Role:"
                  @selectionUpdated="handleRoleUpdate"
                />
              </div>

              <div v-if="showOwnerFields">
                <div>
                  <v-text-input
                    :scope="updateFormScope"
                    :state-key="stateKey"
                    field="owner_job_title"
                    label="Job Title"
                    error-name="job title"
                    validation=""
                  />
                </div>
                <div>
                  <v-phone-number-text-input
                    :scope="updateFormScope"
                    :state-key="stateKey"
                    field="work_phone"
                    type="tel"
                    label="Work Number"
                    error-name="work number"
                    autocomplete="tel"
                    :always-instructions="true"
                    instructions="This is landline phone number which this user will use to make and receive calls"
                    :maxlength="13"
                    validation="required|numeric"
                  />
                </div>
              </div>

              <div class="row align-items-center">
                <div class="col-7">
                  Reset the user's password?
                </div>
                <div class="col-5">
                  <div class="c-notifications-switches">
                    <div
                      class="c-notifications-switches-item justify-content-end"
                    >
                      <v-switch
                        field="reset_user_password"
                        :state-key="stateKey"
                        class="d-inline-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <v-button
                v-if="checkRole('superadmin')"
                class="mt-3"
                :loading="submittingUserData"
                :disabled="submittingUserData"
              >
                Update
              </v-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import UserService from "js-admin/classes/AjaxServices/UserService";
import FlashMessageService from "js-admin/classes/FlashMessageService";
import AccountManagerService from "js-admin/classes/AjaxServices/AccountManagerService";

export default {
  components: {},

  data() {
    return {
      updateFormScope: "updateUserData",
      stateKey: "userData",
      submittingUserData: false,
      showOwnerFields: false,
      passwordStateKey: "passwordGeneration",
    };
  },

  computed: {
    userRole() {
      return this.$store.state.fieldStore[this.stateKey].role;
    },
    accountManagerRole() {
      const role = this.$store.state.fieldStore[this.stateKey].role.name;
      return role === "account_manager" || role === "senior_account_manager";
    },
    resetPassword() {
      return this.$store.state.fieldStore[this.stateKey].reset_user_password;
    },
  },

  mounted() {
    this.$store.dispatch("getRoles");
    if (this.userRole && this.accountManagerRole) {
      this.showOwnerFields = true;
    }
  },

  methods: {
    handleRoleUpdate(val) {
      if (
        val.name === "account_manager" ||
        val.name === "senior_account_manager"
      ) {
        this.showOwnerFields = true;
        return;
      }
      this.showOwnerFields = false;
    },

    submitForm() {
      this.$validator.validateAll(this.updateFormScope).then(async (result) => {
        if (result) {
          this.submittingUserData = true;

          await this.updateUser();

          if (this.accountManagerRole) {
            await this.updateOwner();
          }

          this.submittingUserData = false;
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -100 });
          }, 400);
        }
      });
    },

    updateUser() {
      var data = {
        name: this.$store.state.fieldStore[this.stateKey].name,
        username: this.$store.state.fieldStore[this.stateKey].email_address,
        email_address: this.$store.state.fieldStore[this.stateKey]
          .email_address,
        phone_number:
          this.$store.state.fieldStore[this.stateKey].phone_number_dial_code +
          this.$store.state.fieldStore[this.stateKey].phone_number,
        role: this.$store.state.fieldStore[this.stateKey].role.name,
        uuid: this.$store.state.fieldStore[this.stateKey].uuid,
      };

      if (this.resetPassword) {
        data.reset_user_password = this.resetPassword;
      }

      UserService.updateUser(data)
        .then((response) => {
          FlashMessageService.success("User has been updated.");
          if (this.resetPassword) {
            this.$store.dispatch("updateStoreItem", {
              value: response.data.data.password,
              stateKey: this.passwordStateKey,
              field: "password",
            });
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },

    updateOwner() {
      var ownerData = {
        user_uuid: this.$store.state.fieldStore[this.stateKey].uuid,
        owner_uuid: this.$store.state.fieldStore[this.stateKey].owner_uuid,
        name: this.$store.state.fieldStore[this.stateKey].name,
        email: this.$store.state.fieldStore[this.stateKey].email_address,
        phone_number:
          this.$store.state.fieldStore[this.stateKey].work_phone_dial_code +
          this.$store.state.fieldStore[this.stateKey].work_phone,
        job_title: this.$store.state.fieldStore[this.stateKey].owner_job_title,
      };

      if (ownerData.owner_uuid) {
        AccountManagerService.updateAMOwner(ownerData)
          .then((response) => {
            FlashMessageService.success("Owner data has been updated.");
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      } else {
        AccountManagerService.createAMOwner(ownerData)
          .then((response) => {
            FlashMessageService.success("Owner data has been created.");
            this.$store.dispatch("updateStoreItem", {
              value: response.data.data.uuid,
              stateKey: this.stateKey,
              field: "owner_uuid",
            });
          })
          .catch((error) => {
            FlashMessageService.error(error.response.data.message);
          });
      }
    },
  },
};
</script>
