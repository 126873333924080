<template>
  <div class="v-profile">
    <section class="c-section">
      <div class="row">
        <div class="col-12">
          <h1 class="c-admin-page-title">
            Today's Completed Applications
          </h1>
        </div>
      </div>

      <!-- Search -->
      <v-date-search
        ref="searchDate"
        :scope="reportScope"
        :mode="mode"
        :submit-results="submitResults"
        @get-results="getResults"
      />

      <!-- Summary-->
      <div v-if="reportSummary" class="row">
        <div class="col-12">
          <div class="c-admin-panel">
            <v-accordion :open-first-item="true">
              <v-accordion-item>
                <template v-slot:title>
                  Summary
                </template>

                <div v-if="!submitResults" class="c-admin-table mt-4">
                  <div class="c-admin-table-header -bold">
                    <div class="row">
                      <div class="col-6">
                        Date
                      </div>
                      <div class="col-6">
                        Total
                      </div>
                    </div>
                  </div>
                  <div v-if="reportSummary">
                    <div v-for="(data, index) in summaryData" :key="index">
                      <div v-if="index != 'Total'" class="c-admin-table-row">
                        <div class="row">
                          <div class="col-6">
                            {{ dateFormat(data.date) }}
                          </div>
                          <div class="col-6">
                            {{ data.total }}
                          </div>
                        </div>
                      </div>
                      <div v-else class="c-admin-table-footer -bold">
                        <div class="row">
                          <div class="col-6 text-right">
                            Total:
                          </div>
                          <div class="col-6">
                            {{ data.total }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="c-admin-table-row">
                    <div class="row">
                      <div class="col-12">
                        No results found.
                      </div>
                    </div>
                  </div>
                </div>
              </v-accordion-item>
            </v-accordion>
          </div>
        </div>
      </div>

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />

      <!-- Results -->
      <div class="row mt-4">
        <div class="col-12">
          <div class="c-admin-table">
            <div class="c-admin-table-header -bold">
              <div class="row">
                <div class="col-2">
                  Completed Date
                </div>
                <div class="col-2">
                  Account Name
                </div>
                <div class="col-2">
                  Account Reference
                </div>
                <div class="col-1">
                  Product Type
                </div>
                <div class="col-1">
                  Model Portfolio
                </div>
                <div class="col-2">
                  Application Amount
                </div>
                <div class="col-2">
                  Started Date
                </div>
              </div>
            </div>
            <div v-if="!submitResults">
              <div v-if="reportResults">
                <div
                  v-for="data in reportResults"
                  :key="data.account_reference"
                  class="c-admin-table-row"
                >
                  <div class="row">
                    <div class="col-2">
                      {{ dateTimeFormat(data.completed_on) }}
                    </div>
                    <div class="col-2">
                      {{ data.account_name }}
                    </div>
                    <div class="col-2">
                      {{ data.account_reference }}
                    </div>
                    <div class="col-1">
                      {{ data.product_type }}
                    </div>
                    <div class="col-1">
                      {{ data.model_portfolio }}
                    </div>
                    <div class="col-2">
                      <span v-if="data.amount"> £{{ data.amount }} </span>
                    </div>
                    <div class="col-2">
                      {{ dateTimeFormat(data.started_on) }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="c-admin-table-row">
                <div class="row">
                  <div class="col-12">
                    No results found.
                  </div>
                </div>
              </div>
              <div class="c-admin-table-footer -bold">
                <div class="row">
                  <div class="col-9 text-right">
                    Total:
                  </div>
                  <div class="col-3">
                    {{ reportTotal }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="c-admin-table-row">
              <div class="row">
                <div class="col-12">
                  <v-loader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CSV Export -->
      <v-csv-export
        v-if="reportData"
        class="mt-4"
        @start-csv-export="handleExport"
      />
    </section>
  </div>
</template>

<script>
import VDateSearch from "js-admin/components/Search/DateSearch";
import ReportingService from "js-admin/classes/AjaxServices/ReportingService";

import VCsvExport from "js-admin/components/csv-export/CsvExport";
import mixinReport from "js-admin/mixins/mixinReport";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  components: {
    VDateSearch,
    VCsvExport,
  },
  mixins: [mixinReport],
  data() {
    return {
      reportScope: "reportSearch",
      reportStateKey: "reportSearch",
      reportData: null,
      summaryData: null,
      submitResults: false,
      currentDate: new Date(),
      mode: "single",
    };
  },
  computed: {
    ownerData() {
      return this.$store.state.agents;
    },
    reportResults() {
      return this.reportData;
    },
    reportTotal() {
      let total = 0;
      if (this.reportResults) {
        total = this.reportResults.length;
      }
      return total;
    },
    reportSummary() {
      return this.summaryData;
    },
  },
  mounted() {
    if (!this.checkPermission("reporting_level1")) {
      return this.$router.push({ name: "home" });
    }
  },
  methods: {
    prepareData(downloadCsv = false) {
      let asCsv = {};
      if (downloadCsv) {
        asCsv = {
          as_csv: true,
        };
      }

      let data = this.formatDateData(
        this.$refs.searchDate.fromDate,
        this.$refs.searchDate.toDate
      );
      data = { ...data, ...asCsv };

      return data;
    },

    async getResults(e = null, downloadCsv = false) {
      const data = this.prepareData(downloadCsv);
      if (!downloadCsv) {
        this.submitResults = true;
      }
      return ReportingService.getCompletedApplications(data)
        .then((response) => {
          this.submitResults = false;
          if (downloadCsv) {
            return {
              response,
              label: "Completed_Applications_Report",
            };
          } else {
            this.reportData = response.data.data.results;
            this.summaryData = response.data.data.summary;
          }
        })
        .catch((error) => {
          FlashMessageService.error(error.response.data.message);
        });
    },
  },
};
</script>
