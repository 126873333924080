<template>
  <div class="v-account">
    <div v-if="accountLoadedData">
      <section v-if="!account404" class="c-section">
        <div class="row align-items-start">
          <div class="col-lg-7 col-sm-12">
            <h1 class="c-admin-page-title"> Account for {{ accountName }} </h1>
            <h3 v-if="accountType">
              {{ accountType }}
            </h3>
            <h3 v-if="accountClosed" class="c-admin-alert-text -error">
              Account Closed on {{ dateFormat(accountClosed) }}
            </h3>
            <h3 v-if="accountArchived" class="c-admin-alert-text -error">
              Account Archived on {{ dateFormat(accountArchived) }}
            </h3>
            <h3 v-if="disabledLogin" class="c-admin-alert-text -error">
              Login Disabled
            </h3>
            <h3 v-if="accountDeceased" class="c-admin-alert-text -error">
              Account Holder Deceased
            </h3>
            <a
              :href="false"
              class="c-button -secondary -sidebar"
              @click="accountsPage"
            >
              <strong>
                Back to Accounts List
              </strong>
            </a>
          </div>
          <div class="col-lg-5 col-sm-12">
            <VAccountQuickActions />
          </div>
        </div>

        <div class="row">
          <!-- Start of left section -->
          <div class="col-lg-7 col-sm-12">
            <VUpdateAccountForm
              :investments-accounts-count="accountInvestmentAccountsCount"
            />
          </div>
          <!-- End of left section -->

          <!-- Start of right section -->
          <div class="col-lg-5 col-sm-12">
            <VUpdateOwner />

            <VSystemInformation />

            <VLatestLogins />
          </div>
          <!-- End of right section -->
        </div>

        <div class="row">
          <div class="col-12">
            <VLinkedCompanies />

            <VLinkedTasks
              v-if="
                checkPermission('view_tasks') &&
                  !systemFeatureIsDisabled('disabled_tasks')
              "
              :state-key="stateKey"
            />

            <VNotes
              v-if="checkPermission('view_notes')"
              ref="notes"
              :state-key="stateKey"
              :account-uuid="accountUuid"
            />

            <VLinkedCashWallets />

            <VLinkedInvestmentAccounts
              v-if="
                accountInvestmentAccountsCount &&
                  checkPermission('view_investment_accounts')
              "
            />

            <VLinkedLeads
              v-if="
                checkPermission('view_leads') &&
                  !systemFeatureIsDisabled('disabled_leads')
              "
            />

            <VLinkedBankDetails v-if="checkPermission('show_bank_details')" />

            <VLinkedAmlDetails
              v-if="checkPermission('view_aml')"
              :account-uuid="accountUuid"
            />

            <VCommunications
              v-if="!communicationsDisabled"
              :state-key="stateKey"
              :account-uuid="accountUuid"
              :account-email="accountEmail"
            />

            <VOtherSections :state-key="stateKey" :account-uuid="accountUuid" />

            <VHistoryLogs v-if="checkPermission('view_account_history')" />

            <VArchiveAccount v-if="checkPermission('archive_account')" />
          </div>
        </div>
      </section>
      <div v-else>
        <section class="c-section">
          <div class="row">
            <div class="col-12">
              <h1 class="c-admin-page-title">
                Account Not Found
              </h1>
              <div class="mt-4">
                Please use the buttons below to go back to the accounts list.
              </div>
              <div class="mt-4">
                <a
                  :href="false"
                  class="c-button -admin-small"
                  @click="accountsPage"
                >
                  Back to Accounts List
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <v-loader v-else class="-admin" />
  </div>
</template>

<script>
import VUpdateAccountForm from "js-admin/components/accounts/UpdateAccountForm.vue";
import VUpdateOwner from "js-admin/components/accounts/UpdateOwner.vue";
import VSystemInformation from "js-admin/components/accounts/SystemInformation.vue";
import VAccountQuickActions from "js-admin/components/accounts/QuickActions.vue";
import VLatestLogins from "js-admin/components/accounts/LatestLogins.vue";
import VLinkedCompanies from "js-admin/components/accounts/companies/LinkedCompanies.vue";
import VLinkedLeads from "js-admin/components/accounts/leads/LinkedLeads.vue";
import VLinkedInvestmentAccounts from "js-admin/components/accounts/investment_accounts/LinkedInvestmentAccounts.vue";
import VLinkedAmlDetails from "js-admin/components/accounts/AmlDetails.vue";
import VCommunications from "js-admin/components/accounts/Communication.vue";
import VOtherSections from "js-admin/components/accounts/OtherSections.vue";
import VHistoryLogs from "js-admin/components/accounts/history/HistoryLogs.vue";
import VLinkedTasks from "js-admin/components/accounts/tasks/LinkedTasks.vue";
import VNotes from "js-admin/components/notes/Notes.vue";
import VLinkedBankDetails from "js-admin/components/accounts/bank-details/LinkedBankDetails.vue";
import VLinkedCashWallets from "js-admin/components/accounts/cash-wallets/LinkedCashWallets.vue";
import VArchiveAccount from "js-admin/components/accounts/ArchiveAccount.vue";

export default {
  components: {
    VUpdateAccountForm,
    VUpdateOwner,
    VSystemInformation,
    VAccountQuickActions,
    VLatestLogins,
    VLinkedLeads,
    VLinkedInvestmentAccounts,
    VLinkedAmlDetails,
    VCommunications,
    VOtherSections,
    VHistoryLogs,
    VLinkedTasks,
    VLinkedBankDetails,
    VLinkedCashWallets,
    VNotes,
    VLinkedCompanies,
    VArchiveAccount,
  },
  data() {
    return {
      accountDataReady: false,
      accountNotFound: false,
      stateKey: "accountData",
    };
  },

  computed: {
    accountLoadedData() {
      return this.accountDataReady;
    },
    account404() {
      return this.accountNotFound;
    },
    accountEmail() {
      return this.$store.state.fieldStore[this.stateKey].email;
    },
    accountUuid() {
      return this.$store.state.fieldStore[this.stateKey].uuid;
    },
    accountName() {
      return this.$store.state.fieldStore[this.stateKey].full_name;
    },
    accountType() {
      return this.$store.state.fieldStore[this.stateKey].lead_type;
    },
    accountInvestorType() {
      return this.$store.state.fieldStore[this.stateKey].type;
    },
    accountClosed() {
      return this.$store.state.fieldStore[this.stateKey].closed_on;
    },
    accountArchived() {
      return this.$store.state.fieldStore[this.stateKey].archived_on;
    },
    accountDeceased() {
      return this.$store.state.fieldStore[this.stateKey].date_notified_of_death;
    },
    accountInvestmentAccountsCount() {
      if (
        this.$store.state.fieldStore[this.stateKey].investment_accounts_count
      ) {
        return this.$store.state.fieldStore[this.stateKey]
          .investment_accounts_count;
      } else {
        return 0;
      }
    },
    accountTasksCount() {
      if (this.$store.state.fieldStore[this.stateKey].tasks) {
        return this.$store.state.fieldStore[this.stateKey].tasks.length;
      } else {
        return 0;
      }
    },
    disabledLogin() {
      return this.$store.state.fieldStore[this.stateKey].users[0].disable_login;
    },
    communicationsDisabled() {
      return this.systemFeatureIsDisabled([
        "disabled_phone_system",
        "disabled_secured_messages",
        "disabled_emails",
      ]);
    },
  },

  async mounted() {
    if (!this.checkPermission("view_accounts")) {
      return this.$router.push({ name: "home" });
    }

    await this.$store
      .dispatch("fetchAccountData", this.$route.params.uuid)
      .then((response) => {
        this.$store.dispatch("updateStoreItem", {
          value: response.data.data,
          stateKey: this.stateKey,
          field: null,
        });
      })
      .catch(() => {
        this.accountNotFound = true;
      });
    this.accountDataReady = true;
  },

  methods: {
    accountsPage() {
      this.$router.push({ name: "accounts" });
      return false;
    },
  },
};
</script>
