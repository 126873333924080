<template>
  <div class="c-admin-table-row">
    <div class="row align-items-center">
      <div class="col-2" v-text="dateTimeFormat(payment.created_at)" />
      <div class="col-2" v-text="payment.reference" />
      <div class="col-2">
        {{ capitaliseFirstLetter(payment.provider_name) }}
        <div v-if="payment.external_id">
          <br />
          Ref: {{ payment.external_id }}
        </div>
      </div>
      <div class="col-1" v-text="'£' + convertToPounds(payment.amount)" />
      <div class="col-1" v-text="capitaliseFirstLetter(payment.status)" />
      <div class="col-2 text-center">
        <div v-if="payment.acknowledgment_email_send">
          Yes
        </div>
        <div v-else>
          No
        </div>
      </div>
      <div class="col-2 text-center">
        <a
          :href="false"
          class="c-button -admin-small"
          @click="viewDeposit(payment.uuid)"
        >
          View({{ correctionsCount }})
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentService from "js-admin/classes/AjaxServices/PaymentService";

export default {
  props: {
    payment: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      correctionsCount: null,
    };
  },
  mounted() {
    this.getTransferCorrections(this.payment.uuid);
  },

  methods: {
    async getTransferCorrections(uuid) {
      const bankTransferCorrections = await PaymentService.getBankTransferCorrections(
        {
          uuid,
          return_count: true,
        }
      );

      this.correctionsCount = bankTransferCorrections.data.data.count;
    },
    viewDeposit(uuid) {
      this.$router.push({ name: "deposit", params: { uuid } });
    },
  },
};
</script>
