import { Validator } from "vee-validate";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import numeral from "numeral";
import ValidateService from "js-admin/classes/AjaxServices/ValidateService";

const checkBirthDate = function(month, year, minAge) {
  const currentDate = new Date();
  return (
    new Date(parseInt(year) + parseInt(minAge), month).getTime() <
    new Date(
      parseInt(currentDate.getFullYear()),
      parseInt(currentDate.getMonth()) + 1
    ).getTime()
  );
};

Validator.extend("sortcode_format", {
  getMessage(field) {
    return "invalid format";
  },
  validate(value) {
    if (/^(\d){2}-(\d){2}-(\d){2}$/.test(value)) {
      return true;
    }
    return false;
  },
});

Validator.extend("dob_format", {
  getMessage(field) {
    if (field === "dob") {
      return "Date of Birth needs to be in the format DD/MM/YYYY";
    } else if (field === "date_notified_of_death") {
      return "Deceased Date needs to be in the format DD/MM/YYYY";
    }
    return field + " needs to be in the format DD/MM/YYYY";
  },
  validate(value) {
    if (/^(\d){2}\/(\d){2}\/(\d){4}$/.test(value)) {
      dayjs.extend(customParseFormat);
      return dayjs(value, "DD/MM/YYYY").isValid();
    }

    return false;
  },
});

Validator.extend("over_18", {
  getMessage(field) {
    return "Sorry, you have to be over 18 to invest.";
  },
  validate(value) {
    const dobArray = value.split("/");
    return checkBirthDate(dobArray[1], dobArray[2], 18);
  },
});

Validator.extend("phone_number", {
  getMessage: () => "Please enter a valid phone number.",
  validate: async (value, params) => {
    try {
      const valid = await ValidateService.validatePhone(
        (params[0] ?? "") + value
      );

      return { valid };
    } catch {
      return { valid: false };
    }
  },
});

Validator.extend("password", {
  getMessage(field) {
    return "Your password must be a minimum of 8 characters";
  },
  validate(value) {
    return value.length >= 8;
  },
});

Validator.extend("verify_postcode", {
  getMessage: (field) => `Please enter a full, valid postcode.`,
  validate: (value) => {
    const strongRegex = new RegExp(
      "^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$"
    );
    return strongRegex.test(value);
  },
});

Validator.extend("verify_ni", {
  getMessage: (field) => `Please enter a valid national insurance number.`,
  validate: (value) => {
    const niRegex = new RegExp(
      "^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\\s*\\d\\s*){6}([A-D]|\\s)$"
    );
    return niRegex.test(value.toUpperCase());
  },
});

const dictionary = {
  en: {
    messages: {
      min_value: function(fieldName, params) {
        if (fieldName === "amountAndTransfer") {
          return (
            "Our minimum investment amount is £" +
            numeral(params).format("0,0") +
            ". Please ensure the total new funds and transfer amount exceed £" +
            numeral(params).format("0,0")
          );
        } else if (
          fieldName.indexOf("amount") ||
          fieldName.indexOf("start_value")
        ) {
          return (
            "Our minimum " +
            fieldName +
            " is £" +
            numeral(params).format("0,0") +
            ". Please enter £" +
            numeral(params).format("0,0") +
            " or greater"
          );
        }
        return (
          "The value must be " + numeral(params).format("0,0") + " or greater"
        );
      },
      max_value: function(fieldName, params) {
        if (fieldName == "dob_day") {
          return (
            "The day must be " + numeral(params[0]).format("0") + " or less"
          );
        } else if (fieldName == "dob_month") {
          return (
            "The month must be " + numeral(params[0]).format("0") + " or less"
          );
        } else if (fieldName.indexOf("isa") || fieldName.indexOf("ISA")) {
          // return 'The maximum ' + fieldName + ' in this bond is £' + numeral(params[0]).format('0,0') + '. Please enter £' + numeral(params[0]).format('0,0') + ' or less.'
          return "The maximum ISA investment is £20,000 per tax year. Please enter under £20,000";
        }

        return (
          "The value must be " + numeral(params[0]).format("0,0") + " or less"
        );
      },
      email: function(fieldName, params) {
        switch (fieldName) {
          case "email address":
          case "email":
          case "email_address":
            return "Please enter a valid email address";

          default:
            return "Please enter a valid " + fieldName;
        }
      },
      required: function(fieldName, params) {
        switch (fieldName) {
          case "title":
            return "Please enter a title";

          case "dob":
            return "Please enter a date of birth in this format - DD/MM/YYYY";

          case "first name":
          case "first_name":
            return "Please enter your first name";

          case "last name":
          case "last_name":
            return "Please enter your last name";

          case "email address":
          case "email":
          case "email_address":
            return "Please enter a valid email address";

          case "national insurance number":
          case "national_insurance":
          case "national_insurance_number":
            return "Please enter your national insurance number";

          case "password":
            return "Please enter a valid password";

          case "GDPR preferences":
            return "Please select a contact preference";

          case "gdpr":
          case "gdpr_optin_flag":
          case "gdpr_contact":
            return "Please select a marketing preference";

          case "address_1":
            return "Please enter the 1st line of your address.";

          case "town":
            return "Please enter your town or city.";

          case "postcode_search":
            return "Please enter your postcode.";

          case "addressSelected":
            return "Please enter your postcode and select your address";

          case "postcodeSearch":
          case "previousPostcodeSearch":
            return "Please enter a valid UK postcode";

          case "g-recaptcha-response":
            return "Please confirm you are not a robot";

          case "owner":
            return "Please select an owner";

          default:
            return "Please enter your " + fieldName;
        }
      },
    },
  },
};

Validator.localize(dictionary);
