<template>
  <div
    class="c-input c-phone-number-input"
    :class="[
      't-' + field,
      {
        '-readonly': readonly,
        '-value': value,
        '-disabled': disabled,
        '-error': hasErrors && !focused,
        '-focus': focused,
      },
    ]"
  >
    <div
      class="c-input-group"
      :class="{
        '-error': hasErrors,
        '-focus': focused,
      }"
    >
      <vue-country-code
        :preferred-countries="['gb', 'us', 'ae']"
        :enabled-country-code="true"
        :disabled-fetching-country="true"
        :default-country="countryCode"
        @onSelect="countryCodeSelected"
      >
      </vue-country-code>
      <input
        :ref="fieldRef"
        v-model="value"
        v-validate="phoneValidation()"
        :name="field"
        :type="type"
        :class="{
          '-error': hasErrors,
          '-value': value,
          '-readonly': readonly,
          '-icon': icon,
          '-uppercase': uppercaseField,
        }"
        :data-vv-as="errorName"
        :disabled="disabled"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        class="c-input-field"
        :aria-label="label ? label : 'text input'"
        :readonly="readonly"
        :spellcheck="spellcheck"
        @focus="handleFocus"
        @blur="handleBlur"
        @keypress="keypress($event)"
        @keyup.delete="handleDelete"
        @keyup.enter="$emit('keyup-enter')"
      />
      <label
        :for="field"
        class="c-input-label"
        :class="{
          '-focus': focused || (value !== null && value !== '') || placeholder,
          '-readonly': readonly,
        }"
        @click="this.$nextTick(() => $refs[fieldRef].focus)"
        v-text="label"
      />
      <v-text-input-icons
        v-if="iconVisible"
        :has-errors="hasErrors"
        :value="value"
        :focused="focused && !showErrorsOnFocus"
        :icon="icon"
      />

      <v-error-msg
        v-if="withError"
        :scope="scope"
        :field="field"
        :focused="focused && !showErrorsOnFocus"
      />

      <aside
        v-show="focused || alwaysInstructions"
        v-if="instructions"
        class="c-input-instructions"
        v-html="instructions"
      />
    </div>
  </div>
</template>

<script>
import VErrorMsg from "js-admin/components/form/ErrorMsg";
import VueCountryCode from "vue-country-code";
import VTextInputIcons from "js-admin/components/form/TextInputIcons";

export default {
  name: "VPhoneNumberTextInput",
  components: {
    VTextInputIcons,
    VErrorMsg,
    VueCountryCode,
  },
  inject: ["$validator"],
  props: {
    iconVisible: {
      default: true,
      type: Boolean,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    icon: {
      default: null,
      type: String,
    },
    field: {
      required: true,
      type: String,
    },
    stateKey: {
      required: true,
      type: String,
    },
    scope: {
      default: "",
      type: String,
    },
    namespace: {
      default: null,
      type: String,
    },
    placeholder: {
      default: null,
      type: String,
    },
    uppercaseField: {
      default: false,
      type: Boolean,
    },
    autocomplete: {
      default: "",
      type: String,
    },
    instructions: {
      default: "",
      type: String,
    },
    alwaysInstructions: {
      type: Boolean,
      default: false,
    },
    type: {
      default: "text",
      type: String,
    },
    validation: {
      default: "required",
      type: [String, Object, Boolean],
    },
    label: {
      default: null,
      type: String,
    },
    errorName: {
      default: null,
      type: String,
    },
    maxlength: {
      default: null,
      type: [Number, String],
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    showErrorsOnFocus: {
      default: false,
      type: Boolean,
    },
    withError: {
      default: true,
      type: Boolean,
    },
    focusOnMounted: {
      default: false,
      type: Boolean,
    },
    spellcheck: {
      default: "true",
      type: String,
    },
  },
  data() {
    return {
      focused: false,
    };
  },

  computed: {
    dialCode: {
      get() {
        return this.$store.state.fieldStore[this.stateKey][
          this.field + "_dial_code"
        ];
      },
      set(val) {
        this.$store.commit("updateStore", {
          stateKey: this.stateKey,
          field: this.field + "_dial_code",
          value: val,
        });
      },
    },
    phoneNumber: {
      get() {
        return this.$store.state.fieldStore[this.stateKey][this.field];
      },
      set(val) {
        this.$store.commit("updateStore", {
          stateKey: this.stateKey,
          field: this.field,
          value: val,
        });
      },
    },
    countryCode: {
      get() {
        return this.$store.state.fieldStore[this.stateKey][
          this.field + "_country_code"
        ];
      },
      set(val) {
        this.$store.commit("updateStore", {
          stateKey: this.stateKey,
          field: this.field + "_country_code",
          value: val,
        });
      },
    },
    value: {
      get() {
        let val = this.$store.state.fieldStore[this.stateKey][this.field];
        if (val && this.type === "tel") {
          val = val.replace(/^0+/, "");
          // strip spaces out of phone numbers as it breaks validation
          val = val.replace(/ +/g, "");
        }
        return val;
      },
      set(val) {
        const dispatchTo = "updateStoreItem";
        if (this.uppercaseField) {
          val = val.toUpperCase();
        }
        this.$store.dispatch(dispatchTo, {
          value: val,
          stateKey: this.stateKey,
          field: this.field,
        });

        this.$emit("change", val);
      },
    },
    fieldRef() {
      return this.scope + "-" + this.field;
    },
    hasErrors() {
      return this.errors.has(this.scope + "." + this.field);
    },
  },
  mounted() {
    if (this.focusOnMounted) {
      this.inputFocus();
    }
  },
  methods: {
    addError(msg) {
      const field = this.$validator.fields.find({
        name: this.field,
        scope: this.scope,
      });

      if (!field) return;

      this.$validator.errors.add({
        id: field.id,
        field: this.field,
        msg: msg,
        scope: this.scope,
      });

      field.setFlags({
        invalid: true,
        valid: false,
        validated: true,
      });
    },
    inputFocus() {
      this.$refs[this.fieldRef].focus();
    },
    handleFocus() {
      this.focused = true;
      this.$emit("focus");
    },
    handleBlur() {
      this.focused = false;
      this.$emit("blur");
    },
    handleDelete() {
      this.$emit("delete-pressed");
    },
    keypress($event) {
      this.$emit("keypress", $event);

      $event = $event || window.event;
      const charCode = $event.which ? $event.which : $event.keyCode;

      if (this.validation.includes("numeric")) {
        if (charCode < 48 || charCode > 57) {
          $event.preventDefault();
        } else {
          return true;
        }
      }
    },
    countryCodeSelected({ name, iso2, dialCode }) {
      this.dialCode = "+" + dialCode;
      if (this.countryCode !== iso2) {
        this.phoneNumber = null;
      }
      this.countryCode = iso2;
    },
    phoneValidation() {
      let required = "";
      if (this.validation.includes("required")) {
        required += "required|";
      }
      return required + "phone_number:" + this.dialCode;
    },
  },
};
</script>
