<template>
  <div class="c-admin-table-row">
    <div class="row align-items-center">
      <div class="col-2" v-text="dateTimeFormat(transaction.date)" />
      <div class="col-3" v-text="transaction.reference" />
      <div
        class="col-2"
        v-text="stripUnderScoresAndCapitalise(transaction.type)"
      />
      <div class="col-2" v-text="capitaliseFirstLetter(transaction.status)" />
      <div
        class="col-2"
        v-text="currencySymbol + convertToPounds(transaction.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transaction: {
      required: true,
      type: Object,
    },
  },

  computed: {
    currencySymbol() {
      return this.transaction.currency ? this.transaction.currency.symbol : "£";
    },
  },
};
</script>
