// MIXINS
// =====
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import "vue-datetime/dist/vue-datetime.css";
import "./mixins/mixinCommon";
import "./config/validateOptions"; // vee-validate options
import Vue from "vue";

// Plugins
// =====
import Pusher from "pusher-js";
import VueCountryCode from "vue-country-code";
import VueScrollTo from "vue-scrollto";
import VeeValidate from "vee-validate";
import Toasted from "vue-toasted";
import axios from "axios";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.NODE_ENV,
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
  ],
});

Vue.prototype.$pusher = new Pusher(window.pusher.key, {
  cluster: window.pusher.cluster,
});

Vue.use(VueScrollTo);
Vue.use(VeeValidate);
Vue.use(VueCountryCode);

Vue.use(Toasted, {
  theme: "outline",
  position: "top-center",
  iconPack: "fontawesome",
  duration: 5000,
});

require("./abstract/whiteLabelClientSetup");

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = window.whiteLabelClientApiUrl;
