var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-amount-input",class:{
    '-value': _vm.value,
    '-error': _vm.hasErrors && !_vm.focused,
    '-label': _vm.label,
  }},[(_vm.label)?_c('label',{staticClass:"c-input-label-outside",attrs:{"for":'display_' + _vm.field},domProps:{"textContent":_vm._s(_vm.label)}}):_c('label',{staticClass:"h-hidden",attrs:{"for":'display_' + _vm.field},domProps:{"textContent":_vm._s('Amount input')}}),_c('div',{class:[
      'c-amount-input-container',
      {
        '-error': _vm.hasErrors && !_vm.focused,
        'position-relative': _vm.positionRelative,
        '-focus': _vm.focused,
      },
    ]},[_c('div',{staticClass:"c-amount-input-unit"},[_vm._v("£")]),_c('div',{staticClass:"c-amount-input-slot"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.displayValue),expression:"displayValue"}],ref:_vm.fieldRef,staticClass:"c-amount-input-input",attrs:{"disabled":(_vm.max !== null && _vm.max <= 0) || _vm.disabled,"type":"text","name":'display_' + _vm.field,"inputmode":"numeric","placeholder":_vm.internalPlaceholder,"aria-label":_vm.label ? _vm.label : 'amount'},domProps:{"value":(_vm.displayValue)},on:{"focus":function($event){return _vm.focus($event)},"keypress":_vm.keypress,"blur":_vm.blur,"input":function($event){if($event.target.composing)return;_vm.displayValue=$event.target.value}}})]),(_vm.iconVisible)?_c('v-text-input-icons',{attrs:{"has-errors":_vm.hasErrors,"value":_vm.storeValue,"focused":_vm.focused && !_vm.showErrorsOnFocus,"icon":_vm.icon}}):_vm._e()],1),_c('v-input-hidden',{ref:_vm.field,attrs:{"scope":_vm.scope,"field":_vm.field,"label":_vm.label,"error-name":_vm.errorName,"state-key":_vm.stateKey,"validation":_vm.validation,"namespace":_vm.namespace,"center-error":false}}),(_vm.addAll)?_c('div',{staticClass:"c-amount-input-all type-link",domProps:{"textContent":_vm._s(_vm.addAllText)},on:{"click":_vm.addAllClicked}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }