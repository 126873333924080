<template>
  <div class="c-admin-panel">
    <v-accordion>
      <v-accordion-item>
        <template v-slot:title> Corrections ({{ correctionsCount }})</template>

        <v-add-transfer-correction-form :bank-transfer-uuid="uuid" />

        <v-bank-transfer-correction-table
          v-if="correctionsCount"
          ref="depositsTable"
          :corrections="corrections"
        />
        <div v-else>
          No corrections.
        </div>
      </v-accordion-item>
    </v-accordion>
  </div>
</template>

<script>
import VBankTransferCorrectionTable from "js-admin/components/cash-wallet/deposits/corrections/BankTransferCorrectionTable";
import VAddTransferCorrectionForm from "js-admin/components/cash-wallet/deposits/corrections/AddTransferCorrectionForm";
import PaymentService from "js-admin/classes/AjaxServices/PaymentService";

export default {
  components: {
    VBankTransferCorrectionTable,
    VAddTransferCorrectionForm,
  },

  data() {
    return {
      corrections: [],
    };
  },
  computed: {
    uuid() {
      return this.$store.state.fieldStore.transferData.uuid;
    },
    correctionsCount() {
      return this.corrections.length;
    },
  },
  mounted() {
    PaymentService.getBankTransferCorrections({ uuid: this.uuid }).then(
      (response) => {
        this.corrections = response.data.data;
      }
    );
  },
};
</script>
