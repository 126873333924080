var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-profile"},[_c('section',{staticClass:"c-section"},[_vm._m(0),_c('v-date-search',{ref:"searchDate",attrs:{"scope":_vm.reportScope,"mode":_vm.mode,"submit-results":_vm.submitResults},on:{"get-results":_vm.getResults}}),(_vm.reportData)?_c('v-csv-export',{staticClass:"mt-4",on:{"start-csv-export":_vm.handleExport}}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"c-admin-table mt-4"},[_vm._m(1),(!_vm.submitResults)?_c('div',[(_vm.reportResults)?_c('div',_vm._l((_vm.reportResults),function(data){return _c('div',{key:data.owner_uuid,staticClass:"c-admin-table-row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_vm._v(" "+_vm._s(_vm.dateTimeFormat(data.created_at))+" ")]),_c('div',{staticClass:"col-2"},[_c('router-link',{staticClass:"c-link",attrs:{"to":`/account/${data.account_uuid}`}},[_vm._v(" "+_vm._s(data.account_name)+" ")])],1),_c('div',{staticClass:"col-4"},[(data.answers)?_c('div',_vm._l((data.answers),function(answer,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(answer.label)+": "),(!answer.value)?_c('span',[_c('strong',[_vm._v(" N.A. ")])]):_c('span',[_c('strong',[(index === 'dob')?_c('span',[_vm._v(" "+_vm._s(_vm.dateFormat(answer.value))+" ")]):(
                                index === 'investment' ||
                                  index === 'net_worth' ||
                                  index === 'annual_income' ||
                                  index === 'other_investments' ||
                                  index === 'savings'
                              )?_c('span',[_vm._v(" £"+_vm._s(_vm.convertToPounds(answer.value))+" ")]):_c('span',[_vm._v(" "+_vm._s(answer.value)+" ")])])])])}),0):_vm._e()]),_c('div',{staticClass:"col-1"},[_c('strong',[_vm._v(" "+_vm._s(data.score)+" ")])]),_c('div',{staticClass:"col-2"},[(data.products)?_c('div',_vm._l((data.products),function(value,product){return _c('div',{key:product},[_vm._v(" "+_vm._s(product)+" : "),_c('strong',[(value)?_c('span',[_vm._v(" Yes ")]):_c('span',[_vm._v(" No ")])])])}),0):_vm._e()]),_c('div',{staticClass:"col-1"},[_c('strong',[_vm._v(" "+_vm._s(data.version)+" ")])])])])}),0):_c('div',{staticClass:"c-admin-table-row"},[_vm._m(2)]),_c('div',{staticClass:"c-admin-table-footer -bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 text-right"},[_vm._v(" Total: ")]),_c('div',{staticClass:"col-2"},[_vm._v(" "+_vm._s(_vm.reportTotal)+" ")])])])]):_c('div',{staticClass:"c-admin-table-row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-loader')],1)])])])])]),(_vm.reportData)?_c('v-csv-export',{staticClass:"mt-4",on:{"start-csv-export":_vm.handleExport}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"c-admin-page-title"},[_vm._v(" Suitability Report ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-admin-table-header -bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_vm._v(" Date ")]),_c('div',{staticClass:"col-2"},[_vm._v(" Account Name ")]),_c('div',{staticClass:"col-4"},[_vm._v(" Answers ")]),_c('div',{staticClass:"col-1"},[_vm._v(" Score ")]),_c('div',{staticClass:"col-2"},[_vm._v(" Eligible Products ")]),_c('div',{staticClass:"col-1"},[_vm._v(" Version ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._v(" No results found. ")])])
}]

export { render, staticRenderFns }