var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-account"},[(_vm.accountLoadedData)?_c('div',[(!_vm.account404)?_c('section',{staticClass:"c-section"},[_c('div',{staticClass:"row align-items-start"},[_c('div',{staticClass:"col-lg-7 col-sm-12"},[_c('h1',{staticClass:"c-admin-page-title"},[_vm._v(" Account for "+_vm._s(_vm.accountName)+" ")]),(_vm.accountType)?_c('h3',[_vm._v(" "+_vm._s(_vm.accountType)+" ")]):_vm._e(),(_vm.accountClosed)?_c('h3',{staticClass:"c-admin-alert-text -error"},[_vm._v(" Account Closed on "+_vm._s(_vm.dateFormat(_vm.accountClosed))+" ")]):_vm._e(),(_vm.accountArchived)?_c('h3',{staticClass:"c-admin-alert-text -error"},[_vm._v(" Account Archived on "+_vm._s(_vm.dateFormat(_vm.accountArchived))+" ")]):_vm._e(),(_vm.disabledLogin)?_c('h3',{staticClass:"c-admin-alert-text -error"},[_vm._v(" Login Disabled ")]):_vm._e(),(_vm.accountDeceased)?_c('h3',{staticClass:"c-admin-alert-text -error"},[_vm._v(" Account Holder Deceased ")]):_vm._e(),_c('a',{staticClass:"c-button -secondary -sidebar",attrs:{"href":false},on:{"click":_vm.accountsPage}},[_c('strong',[_vm._v(" Back to Accounts List ")])])]),_c('div',{staticClass:"col-lg-5 col-sm-12"},[_c('VAccountQuickActions')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-7 col-sm-12"},[_c('VUpdateAccountForm',{attrs:{"investments-accounts-count":_vm.accountInvestmentAccountsCount}})],1),_c('div',{staticClass:"col-lg-5 col-sm-12"},[_c('VUpdateOwner'),_c('VSystemInformation'),_c('VLatestLogins')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('VLinkedCompanies'),(
              _vm.checkPermission('view_tasks') &&
                !_vm.systemFeatureIsDisabled('disabled_tasks')
            )?_c('VLinkedTasks',{attrs:{"state-key":_vm.stateKey}}):_vm._e(),(_vm.checkPermission('view_notes'))?_c('VNotes',{ref:"notes",attrs:{"state-key":_vm.stateKey,"account-uuid":_vm.accountUuid}}):_vm._e(),_c('VLinkedCashWallets'),(
              _vm.accountInvestmentAccountsCount &&
                _vm.checkPermission('view_investment_accounts')
            )?_c('VLinkedInvestmentAccounts'):_vm._e(),(
              _vm.checkPermission('view_leads') &&
                !_vm.systemFeatureIsDisabled('disabled_leads')
            )?_c('VLinkedLeads'):_vm._e(),(_vm.checkPermission('show_bank_details'))?_c('VLinkedBankDetails'):_vm._e(),(_vm.checkPermission('view_aml'))?_c('VLinkedAmlDetails',{attrs:{"account-uuid":_vm.accountUuid}}):_vm._e(),(!_vm.communicationsDisabled)?_c('VCommunications',{attrs:{"state-key":_vm.stateKey,"account-uuid":_vm.accountUuid,"account-email":_vm.accountEmail}}):_vm._e(),_c('VOtherSections',{attrs:{"state-key":_vm.stateKey,"account-uuid":_vm.accountUuid}}),(_vm.checkPermission('view_account_history'))?_c('VHistoryLogs'):_vm._e(),(_vm.checkPermission('archive_account'))?_c('VArchiveAccount'):_vm._e()],1)])]):_c('div',[_c('section',{staticClass:"c-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"c-admin-page-title"},[_vm._v(" Account Not Found ")]),_c('div',{staticClass:"mt-4"},[_vm._v(" Please use the buttons below to go back to the accounts list. ")]),_c('div',{staticClass:"mt-4"},[_c('a',{staticClass:"c-button -admin-small",attrs:{"href":false},on:{"click":_vm.accountsPage}},[_vm._v(" Back to Accounts List ")])])])])])])]):_c('v-loader',{staticClass:"-admin"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }