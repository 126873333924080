import APIService from "js-admin/classes/APIService";

export default class ValidateService {
  static CRUD_URL = "validate";

  static async validatePhone(phoneNumber) {
    try {
      await APIService.request(this.CRUD_URL + "/phone-number/" + phoneNumber);

      return true;
    } catch (Error) {
      return false;
    }
  }
}
