var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"c-admin-panel"},[_c('div',{staticClass:"c-admin-panel-section"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s(_vm.deposit.reference)+" ")])])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s(_vm.capitaliseFirstLetter(_vm.deposit.provider_name))+" ")])])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s("£" + _vm.convertToPounds(_vm.deposit.amount))+" ")])])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s("£" + _vm.convertToPounds(_vm.deposit.amount_after_correction))+" ")])])]),_c('div',{staticClass:"row mt-2"},[_vm._m(5),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" "+_vm._s(_vm.capitaliseFirstLetter(_vm.deposit.status))+" ")])])]),_c('div',{staticClass:"row mt-2"},[_vm._m(6),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.capitaliseFirstLetter(_vm.deposit.external_status))+" ")])])]),(_vm.deposit.currency)?_c('div',{staticClass:"row mt-2"},[_vm._m(7),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.deposit.currency.code)+" ")])])]):_vm._e(),_c('div',{staticClass:"row mt-2"},[_vm._m(8),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" "+_vm._s(_vm.dateTimeFormat(_vm.deposit.created_at))+" ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"c-admin-panel-title"},[_vm._v(" Deposit Information ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Reference: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Provider Name: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Amount: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Amount After Correction: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',{staticClass:"c-admin-alert-text"},[_vm._v(" Status: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" External Status: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Currency: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('div',[_vm._v(" Date: ")])])
}]

export { render, staticRenderFns }