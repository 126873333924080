<template>
  <div class="mb-4">
    <a :href="false" class="c-button" @click="showManualTransferForm">
      {{ addManualTransferLabel }}
    </a>
    <div v-if="showCreateForm" class="c-admin-panel">
      <form
        :data-vv-scope="scope"
        class="c-admin-form mb-2"
        @submit.prevent="createManualTransfer"
      >
        <h3 class="c-admin-panel-title">
          Add Manual Bank Transfer
        </h3>
        <div>
          <v-amount-input
            ref="TransferAmount"
            :state-key="stateKey"
            :scope="scope"
            field="amount"
            label="Transfer Amount"
            validation="required|min_value:1"
            :allow-float="true"
          />
        </div>
        <v-button
          :loading="createInProgress"
          :disabled="createInProgress"
          class="-admin-small"
        >
          Add
        </v-button>
      </form>
    </div>
  </div>
</template>

<script>
import PaymentService from "js-admin/classes/AjaxServices/PaymentService";
import FlashMessageService from "js-admin/classes/FlashMessageService";

export default {
  props: {
    cashWalletUuid: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      scope: "createManualTransferForm",
      stateKey: "createManualTransferForm",
      showCreateForm: false,
      createInProgress: false,
    };
  },

  computed: {
    addManualTransferLabel() {
      if (this.showCreateForm) {
        return "- Hide Form";
      } else {
        return "+ Add Manual Transfer";
      }
    },
  },

  methods: {
    showManualTransferForm() {
      this.showCreateForm = !this.showCreateForm;
    },

    createManualTransfer() {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          const data = {
            amount: this.$store.state.fieldStore[this.stateKey].amount,
            cash_wallet_uuid: this.cashWalletUuid,
          };

          this.createInProgress = true;

          PaymentService.createManualBankTransfer(data)
            .then(() => {
              this.createInProgress = false;
              FlashMessageService.success(
                "Manual Transfer has been created. The page will reload shortly to update info"
              );

              this.$store.dispatch("updateStoreItem", {
                value: null,
                stateKey: this.stateKey,
                field: "amount",
              });

              this.showCreateForm = false;

              setTimeout(() => {
                window.location.reload();
              }, 200);
            })
            .catch((error) => {
              this.createInProgress = false;
              FlashMessageService.error(error.response.data.message);
            });
        } else {
          setTimeout(() => {
            this.$scrollTo(".c-input-error", 500, { offset: -150 });
          }, 400);
        }
      });
    },
  },
};
</script>
